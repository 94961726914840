import Axios from "axios";
import AuthService from "../Services/AuthServices";
import { Buffer } from 'buffer'
// import ApiServices from "../Services/ApiServices";
// import { getNewAccessToken } from "../Apis/EndPoints";

export const getConfig = () => {
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    };
    return config;
};

export const userLogin = async (path, body) => {
    const authService = AuthService.get();
    const config = getConfig();
    const result = await Axios
        .post(`${process.env.REACT_APP_BACKEND_BASE_URL}${path}`, body, config)
        .then((res) => {
            console.log(res);
            const token = res.data.token
            const authUser = res?.data?.userData;
            authService.Login(token, authUser);
            const result = {
                ...res.data,
                status: true,
                isNewUser:authUser.isNewUser,
                user:authUser,
                message: "Login Successfully!",
            };
            return result;
        })
        .catch((error) => {
            console.log("error", error);
            if (error.response) {
                const result = {
                    status: false,
                    message: "Incorrect username or password",
                };
                return result;
            }
            const result = {
                status: false,
            };
            return result;
        });

    return result;
};

export const getAccessToken = async () => {
    // try {
    //     const user = JSON.parse(localStorage.getItem("user"))
    //     const refreshToken = localStorage.getItem('refreshToken');
    //     const { data } = await ApiServices.post(getNewAccessToken, { email: user.name, refreshToken: refreshToken });
    //     window.alert("newAccessToken API Called");
    //     localStorage.setItem('accessToken', data.newAccessToken);
    //     window.location.reload()
    // } catch (error) {
    //     console.log(error);
    // }
};

export const isAccessTokenExpired = (accessToken) => {
    const newAccessToken = accessToken?.split('.')[1]
    if (newAccessToken) {
        const decodedToken = JSON.parse(Buffer?.from(newAccessToken, 'base64'))
        localStorage.setItem("userId",decodedToken.username)

        const expirationTime = decodedToken.exp * 1000;
        const currentTime = Date.now();
        if (expirationTime < currentTime) {
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }
}   
   
export const isTokenExpired = (expirationTime) => {
    const currentTime = Date.now();
    if (expirationTime < currentTime) {
        return true;
    } else {
        return false;
    }
}