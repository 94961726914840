
import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Content from "../sections/EditProfile/Content";

class EditProfile extends Component {

  render() {
    return (
      <Fragment>
        <MetaTags>
          <title> {process.env.REACT_APP_WEBNAME} | EditProfile</title>
          <meta name="description" content="#" />
        </MetaTags>
        <Content />
      </Fragment>
    );
  }
}

export default EditProfile;