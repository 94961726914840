import React from "react";
import { metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import { configureChains, createClient, WagmiConfig } from "wagmi";

const PolygonMainnet = {
  id: 137,
  name: "PolygainMainnet",
  network: "Polygon Mainnet",
  iconUrl:
    "https://cryptologos.cc/logos/versions/ethereum-eth-logo-animated.gif?v=024",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "Polygon",
    symbol: "MATIC",
  },
  rpcUrls: {
    default: "https://polygon-rpc.com/",
  },
  blockExplorers: {
    default: { name: "polygonscan", url: "https://polygon-rpc.com/" },
    etherscan: { name: "polygonscan", url: "https://polygon-rpc.com/" },
  },
  testnet: false,
};

const PolygonTestnet = {
  id: 80002,
  name: "Amoy",
  network: "Polygon Testnet",
  iconUrl:
    "https://cryptologos.cc/logos/versions/ethereum-eth-logo-animated.gif?v=024",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "Amoy",
    symbol: "MATIC",
  },
  rpcUrls: {
    default: "https://rpc-amoy.polygon.technology/",
  },
  blockExplorers: {
    default: { name: "polygonscan", url: "https://www.oklink.com/amoy" },
    etherscan: { name: "polygonscan", url: "https://www.oklink.com/amoy" },
  },
  testnet: false,
};

const { provider, chains } = configureChains(
  [PolygonTestnet, PolygonMainnet],
  [jsonRpcProvider({ rpc: (chain) => ({ http: chain.rpcUrls.default }) })]
);

let { connectors } = getDefaultWallets({
  appName: "BlockCerti",
  chains,
});
connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [metaMaskWallet({ chains })],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

export default function RainbowKit({ children }) {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>{children}</RainbowKitProvider>
    </WagmiConfig>
  );
}
