import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import emptyImage from "../../../assets/img/misc/emptyImage.svg"
import { Button, Card, Col, Row, Select, Spin } from "antd";
import FileUpload from "../../../utility/ipfs";
import ApiServices from "../../../Services/ApiServices";
import ImageContainer from "../../ImageContainer/ImageContainer";

function Content(props) {



    const { Option } = Select;
    const hiddenFileInput = React.useRef(null);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [courseData, setCourseData] = useState([])
    const userId = localStorage.getItem('userId')
    const [Profilepic, setProfilepic] = useState("");
    const [Signaturepic, setSignaturepic] = useState("");
    const [profile, setProfile] = useState('')
    const [sigPic, setSigPic] = useState('')
    const [email, setEmail] = useState('')
    const [symbol, setSymbol] = useState('')
    const [courses, setCourses] = useState([])
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [url, setUrl] = useState('')
    const [address, setAddress] = useState('')
    const [description, setDescription] = useState('')
    const [contractAddress, setContractAddress] = useState('')
    const [subscriptionType, setSubscriptionType] = useState('Free')
    const [leftCredit, setLeftCredit] = useState('100')
    const [templateId, setTemplateId] = useState('1')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState({});
    const instituteId = localStorage.getItem('instituteId')
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);


    useEffect(() => {
        console.log("address", contractAddress)
    }, [contractAddress])

    useEffect(() => {
        setSignature()
    }, [Signaturepic])

    async function setSignature() {
        const signature = await uploadImageSignatureOnIpfs()
        setSigPic(signature)
    }

    async function setFunProfile() {
        const logoUrl = await uploadImageLogoOnIpfs()
        setProfile(logoUrl)
    }

    useEffect(() => {
        setFunProfile();
    }, [Profilepic])

    const isValid2 = () => {
        let isError = false;
        let error = {};
        if (email === "") {
            error.email = "Email is required";
            isError = true;
        }
        const testEmailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
        console.log("testEmailValid", testEmailValid)
        if (testEmailValid == false) {
            error.email = "Invalid Email format";
            isError = true;
        } else {
            error.email = "";
            isError = false;
        }

        if (phone === "") {
            error.phone = "Mobile No is required";
            isError = true;
        }
        if (url === "") {
            error.url = "UrlLink is required";
            isError = true;
        }
        if (address === "") {
            error.address = "Institute Address is required";
            isError = true;
        }
        if (description === "") {
            error.description = "Description is required";
            isError = true;
        }
        setError(error);
        return isError;
    }
    const handleNextCard = () => {
        setShowCards(false);
        setCurrentCardIndex((prevIndex) => (prevIndex + 1) % 2);
    };
    const handlePreviousCard = () => {
        setShowCards(true);
        setCurrentCardIndex((prevIndex) => (prevIndex - 1 + 2) % 2);
    };
    const handlesubmit = async () => {
        if (!isValid2()) {
            setIsButtonDisabled(true)
            setIsLoading(true)
            console.log(name, email, url, description, phone);
            console.log(profile)
            console.log("typeof(profile", typeof (profile))
            // if (Profilepic) {
            //     const logoUrl = await uploadImageLogoOnIpfs()
            //     setProfile(logoUrl)
            // }

            // if (Signaturepic) {
            //     const signature = await uploadImageSignatureOnIpfs()
            //     setSigPic(signature)
            // }


            const body = {
                name: name,
                symbol: symbol,
                contractAddress: contractAddress,
                logo: profile,
                signature: sigPic,
                subscriptionType: subscriptionType,
                leftCredit: leftCredit,
                email: email,
                phoneNumber: phone,
                wabsiteUrl: url,
                templateId: templateId,
                userId: userId,
                courses: courses,
                description: description,
                address: address
            }
            console.log(body)
            ApiServices.put(`/institute/update/`, instituteId, body).then((res) => {
                navigate("/profile");
                setIsLoading(false)
                setIsButtonDisabled(false)
                console.log("res", res);
            }).catch((err) => {
                console.log("err", err);
                setIsLoading(false)
                setIsButtonDisabled(false)
                toast.error("Something went wrong")
            })

        } else {
            toast.error("same field not provided")
        }
    }

    const uploadImageLogoOnIpfs = async () => {
        try {
            const res = await fetch(Profilepic);
            const blob = await res.blob();
            const fileName = `logo_${userId}.png`
            const file = new File([blob], fileName, { type: "image/png" });
            const result = await FileUpload(file)
            return result
        } catch (err) {
            console.log("error while image upload on ipfs", err);
            toast.error("try again...")
        }

    }

    const uploadImageSignatureOnIpfs = async (type) => {
        try {
            const res = await fetch(Signaturepic);
            const blob = await res.blob();
            const fileName = `sig_${userId}.png`
            const file = new File([blob], fileName, { type: "image/png" });
            const result = await FileUpload(file)
            return result
        } catch (err) {
            console.log("error while image upload on ipfs", err);
            toast.error("institute setup fail...")
        }

    }
    const getInstituteByIdProfile = () => {
        setLoader(true)
        console.log("userId", userId);
        ApiServices.get(`/institute/getIntitute/${userId}`)
            .then((res) => {
                setLoader(false)
                console.log("reeeeeeee", res);
                setProfile(res.data?.institute.logo)
                setSigPic(res.data?.institute.signature)
                setName(res.data?.institute.name)
                setSymbol(res.data?.institute.symbol)
                setEmail(res.data?.institute.email)
                setPhone(res.data?.institute.phoneNumber)
                setCourses(res.data?.institute.courses)
                setCourseData(res?.data?.institute?.courses)
                setUrl(res.data?.institute.wabsiteUrl)
                setAddress(res.data?.institute.address)
                setDescription(res.data?.institute.description)
            }).catch((error) => {
                setLoader(false)
                console.log("errorerrorerror", error);
            })
    }
    useEffect(() => {
        getInstituteByIdProfile()
    }, [])
    const handleChange = (value) => {
        setCourses(value)
    };
    const [userInfo, setUserData] = useState({
        profilepic: "",
        signaturepic: "",
    });
    console.log("userData", userInfo);
    useEffect(() => {
        if (userInfo?.profilepic === Profilepic) {
            setProfilepic(userInfo?.profilepic);
        }
    }, []);

    useEffect(() => {
        if (userInfo?.signaturepic === Signaturepic) {
            setSignaturepic(userInfo?.signaturepic);
        }
    }, []);

    const profileImgChangHandler = (e) => {
        if (e.target.value === "") {
            toast.warn("please try again");
        } else {
            const imgReader = new FileReader();
            imgReader.onloadend = (event) => {
                setProfilepic(event.target.result);
                setUserData((userInfo) => ({
                    ...userInfo,
                    profilepic: event.target.result,
                }));
            };
            imgReader.readAsDataURL(e.target.files[0]);
        }
    };
    const signatureImgChangHandler = (e) => {
        if (e.target.value === "") {
            toast.warn("please try again");
        } else {
            const imgReader = new FileReader();
            imgReader.onloadend = (event) => {
                setSignaturepic(event.target.result);
                setUserData((userInfo) => ({
                    ...userInfo,
                    signaturepic: event.target.result,
                }));
            };
            imgReader.readAsDataURL(e.target.files[0]);
        }
    };
    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const [showCards, setShowCards] = useState(true);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);

    return (
        <div className="section">
            <div className="container">
                {loader ? <Spin className="center" /> : <Row gutter={16}>
                    <Col span={4} />
                    <Col span={16}>
                        {showCards ?
                            <Card title="Institute" bordered={false} className="mt-4"
                                extra={
                                    <span>Step 1 of 2</span>
                                }>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <div className="col-lg-12 form-group">
                                            <div className="rounded-circle" style={{ width: "150px", height: "150px" }}>
                                                <ImageContainer imageUrl={Profilepic ? userInfo?.profilepic : profile} style={{borderRadius:"50%"}}/></div>
                                        </div>
                                        <div className="col-lg-12 form-group">
                                            <div className="custom-file">
                                                <input
                                                    className="form-control form-control-lg "
                                                    id="formFileLg"
                                                    ref={hiddenFileInput}
                                                    type="file"
                                                    accept="image/png, image/jpeg"
                                                    style={{ height: "10px", display: "none" }}
                                                    onChange={(event) => {
                                                        profileImgChangHandler(event);
                                                    }}
                                                />
                                                <div style={{ paddingLeft: "20px" }}>
                                                    <button
                                                        type="primary"
                                                        className="upload-icon-btn mt-10"
                                                        onClick={handleClick}
                                                    >
                                                        Upload Photo
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={14}>
                                        <form>
                                            <div className="row">
                                                <div className="col-lg-12 form-group">
                                                    <label>Institute Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter your Institute Name"
                                                        value={name}
                                                        disabled
                                                        onChange={(e) => { setName(e.target.value) }}
                                                    />
                                                    <span className="text-danger  ">
                                                        {error && error.name ? error.name : ""}
                                                    </span>
                                                </div>
                                                <div className="col-lg-12 form-group">
                                                    <label>Symbol</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter your Symbol"
                                                        value={symbol}
                                                        disabled
                                                        maxLength={4}
                                                        onChange={(e) =>
                                                            setSymbol(e.target.value.toUpperCase())
                                                        }
                                                    />
                                                    <span className="text-danger  ">
                                                        {error && error.symbol ? error.symbol : ""}
                                                    </span>
                                                </div>
                                                <div className="col-lg-12 form-group">
                                                    <label>Courses</label>
                                                    <Select
                                                        mode="tags"
                                                        style={{ width: '100%' }}
                                                        placeholder="Add course"
                                                        onChange={handleChange}
                                                        size="large"
                                                        defaultValue={courses}

                                                    />
                                                </div>
                                                <div className="col-lg-12 form-group">
                                                    <label>Signature</label>
                                                    <input
                                                        className="form-control form-control-lg "
                                                        id="formFileLg"
                                                        type="file"
                                                        accept="image/png, image/jpeg"
                                                        style={{ height: "10px" }}
                                                        onChange={(event) => {
                                                            signatureImgChangHandler(event);
                                                        }}
                                                    />
                                                    <div className="col-lg-3">
                                                        <button
                                                            type="button"
                                                            className="btn-custom mt-4"
                                                            onClick={handleNextCard}
                                                        >
                                                            {loader ? "Loading..." : "Next"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </Col>
                                </Row>
                            </Card>
                            :
                            <Card title="Institute Contact Info" bordered={false} className="mt-4"
                                extra={
                                    <span>Step 2 of 2</span>
                                }>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <div className="col-lg-12 form-group">
                                            <div  style={{ width: "150px", height: "150px" }}>
                                                <ImageContainer imageUrl={Profilepic ? userInfo?.profilepic : profile}style={{borderRadius:"50%"}} /></div>
                                        </div>
                                        <div className="col-lg-12 form-group">
                                            <div className="custom-file">
                                                <input
                                                    className="form-control form-control-lg "
                                                    id="formFileLg"
                                                    ref={hiddenFileInput}
                                                    type="file"
                                                    accept="image/png, image/jpeg"
                                                    style={{ height: "10px", display: "none" }}
                                                    onChange={(event) => {
                                                        profileImgChangHandler(event);
                                                    }}
                                                />
                                                <div style={{ paddingLeft: "30px" }}>
                                                    <button
                                                        type="primary"
                                                        className="upload-icon-btn mt-10"
                                                        onClick={handleClick}
                                                    >
                                                        Edit Photo
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={14}>
                                        <form>
                                            <div className="row">
                                                <div className="col-lg-12 form-group">
                                                    <label>Email Address</label>
                                                    <input
                                                        type="email"
                                                        placeholder="Enter your Email Address"
                                                        className="form-control"
                                                        value={email}
                                                        onChange={(e) =>
                                                            setEmail(e.target.value)
                                                        }
                                                    />
                                                    <span className="text-danger  ">
                                                        {error && error.email ? error.email : ""}
                                                    </span>
                                                </div>
                                                <div className="col-lg-12 form-group">
                                                    <label>Mobile No</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter your Mobile No"
                                                        value={phone}
                                                        maxLength={10}
                                                        onChange={(e) => {
                                                            if (e.target.value.length <= 10) {
                                                                setPhone(e.target.value);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <span className="text-danger  ">
                                                        {error && error.phone ? error.phone : ""}
                                                    </span>
                                                </div>
                                                <div className="col-lg-12 form-group">
                                                    <label>Institute Website</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter your Institute Website "
                                                        value={url}
                                                        onChange={(e) =>
                                                            setUrl(e.target.value)
                                                        }
                                                    />
                                                    <span className="text-danger  ">
                                                        {error && error.url ? error.url : ""}
                                                    </span>
                                                </div>
                                                <div className="col-lg-12 form-group">
                                                    <label>Institute Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter your Institute Address"
                                                        value={address}
                                                        onChange={(e) =>
                                                            setAddress(e.target.value)
                                                        }
                                                    />
                                                    <span className="text-danger  ">
                                                        {error && error.address ? error.address : ""}
                                                    </span>
                                                </div>
                                                <div className="col-lg-12 form-group">
                                                    <label for="exampleFormControlTextarea1">Description</label>
                                                    <textarea
                                                        className="form-control-textarea"
                                                        rows="7"
                                                        value={description}
                                                        placeholder="Description"
                                                        onChange={(e) => setDescription(e.target.value)}
                                                    ></textarea>
                                                    <span className="text-danger  ">
                                                        {error && error.description ? error.description : ""}
                                                    </span>
                                                </div>
                                                <div className="col-lg-12 form-group d-flex">
                                                    <div className="col-lg-3">
                                                        <button
                                                            type="button"
                                                            onClick={handlePreviousCard}
                                                            className={isButtonDisabled ? "btn-custom mt-4 opacity-50" : "btn-custom mt-4"}
                                                            disabled={isButtonDisabled}

                                                        >
                                                            Previous
                                                        </button>
                                                    </div> <div className="col-lg-2" />
                                                    <div className="col-lg-3">
                                                        <button
                                                            type="button"
                                                            className={isButtonDisabled ? "btn-custom mt-4 opacity-50" : "btn-custom mt-4"}
                                                            disabled={isButtonDisabled}
                                                            onClick={handlesubmit}
                                                        >
                                                            {isLoading ? "Saving..." : "Save"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </Col>
                                </Row>
                            </Card>
                        }
                    </Col>
                    <Col span={4} />
                </Row>
                }
            </div>
        </div>
    );
}

export default Content;