import { GetCurrentUser, GetCurrentInstitute } from '../../Redux/ApiServices'
import {getSpecificInstitute} from '../Apis/Endpoint'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from './Header'
import { useNavigate, useLocation } from 'react-router-dom'
import ApiServices from '../../Services/ApiServices'
import { toast } from 'react-toastify'



export default function Layout({ children }) {
    const userInfo = useSelector((state) => state.user)
    const intituteInfo = useSelector((state) => state.institute)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        dispatch(GetCurrentUser())
    }, [dispatch])

    useEffect(() => {
        if (userInfo?.userInfo?.isNewUser) {
            navigate("/institute-setup")
        } else {
            console.log(currentPath);
                navigate(currentPath)
        }
    }, [userInfo?.userInfo?.isNewUser])

    // useEffect(()=>{
      
    // },[])


    return (
        <div>
            <Header />
            <div className="">
                {children && children}
            </div>
        </div>
    )
}
