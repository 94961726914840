import { Card, Col, Row } from "antd";
import React from "react";
import linkEx from "../../../assets/img/misc/expirelink.svg"

export default function LinkExpire() {
    return (
        <div className="section">
            <div className="container">
                <Row>
                    <Col span={12} offset={6}>
                        <Card className="text-center" style={{borderRadius:"50px"}}>
                            <img src={linkEx}/> 
                            <h5 className="mt-5">Your URL Link has been Expired</h5>
                        </Card>   
                    </Col>
                </Row>
            </div></div>
    )
}
