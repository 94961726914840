import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import { WalletChatWidget } from "react-wallet-chat";
import "react-wallet-chat/dist/index.css";
import { useSelector } from "react-redux";
import Hotjar from "@hotjar/browser";
import PrivateRoutes from "./middleware/PrivateRoutes";
import PublicRoutes from "./middleware/PublicRoutes";
import UserPublicPage from "./components/pages/UserPublicPage";
import EditProfile from "./components/pages/EditProfile";
// Preloader

// Home Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const Resetpassword = React.lazy(() =>
  import("./components/pages/Resetpassword")
);
const Forgotpassword = React.lazy(() =>
  import("./components/pages/Forgotpassword")
);
const Verifyemail = React.lazy(() => import("./components/pages/Verifyemail"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Login = React.lazy(() => import("./components/pages/Login"));
const Register = React.lazy(() => import("./components/pages/Register"));
const Profile = React.lazy(() => import("./components/pages/Profile"));
const InstituteProfile = React.lazy(() => import("./components/pages/InstituteProfile"))
function App() {
  return (
    <>
      <div className="walletchatwidget">
      </div>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path="/login" element={<PublicRoutes><Login /></PublicRoutes>} />
          <Route path="/register" element={<PublicRoutes><Register /></PublicRoutes>} />
          <Route path="/reset-password" element={<Resetpassword />} />
          <Route path="/forgot-password" element={<Forgotpassword />} />
          <Route path="/verify-email" element={<Verifyemail />} />
          <Route exact path="/" element={<PrivateRoutes><Home /></PrivateRoutes>} />
          <Route path="/institute-setup" element={<PrivateRoutes><Profile /></PrivateRoutes>} />
          <Route path="/profile" element={<PrivateRoutes><InstituteProfile /></PrivateRoutes>} />
          <Route path="/claimcertificate/:certificateId" element={<UserPublicPage />} />

          <Route path="/edit-profile" element={<PrivateRoutes><EditProfile /></PrivateRoutes>} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
