import { Buffer } from 'buffer'

class AuthService {
    static instance = null;
    authToken = null;
    token = null
    userId = null;
    
    static get() {
        if (AuthService.instance == null) {
            console.log("AuthService initializing");
            AuthService.instance = new AuthService();
        };

        return AuthService.instance;
    }
    Login = (token, authUser) => {
        this.tbufferoken = token;
        // this.authUser = authUser;
        localStorage.setItem("accessToken", token.accessToken);
        localStorage.setItem("refreshToken", token.refreshToken);
        localStorage.setItem("accessTokenExp", token.accessTokenExp);
        localStorage.setItem("refreshTokenExp", token.refreshTokenExp);
        // localStorage.setItem("user", JSON.stringify(authUser))
        // localStorage.setItem("id", JSON.stringify(authUser._id))
    };

    Logout = () => {
        localStorage.clear();
        if (localStorage.getItem("accessToken" === null)) {
            return true;
        }
        return false;
    };

    isAuthenticated = () => {
        if (this.authToken === null) {
            try {
                const authTokenFromStorage = localStorage.getItem("accessToken");
                if (authTokenFromStorage) {
                    this.authToken = authTokenFromStorage;
                }
            } catch (e) {
                console.log(e);
                this.authToken = null;
                this.user = null;
            }
        }
        return this.authToken;
    }
    getUserId = ()=>{
        if (this.userId === null) {
            try {
                const userIdFromStorage = localStorage.getItem("userId");
                if (userIdFromStorage) {
                    this.userId = userIdFromStorage;
                }
            } catch (e) {
                console.log(e);
                this.userId = null;
            }
        }
        return this.userId;
     
    }

    // isAccessTokenExpired = (accessToken) => {
    //     const decodedToken = JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64'))
    //     localStorage.setItem('userId',decodedToken.username)
    //     console.log("decodedToken",decodedToken)
    //     const expirationTime = decodedToken.exp * 1000;
    //     const currentTime = Date.now();
    //     if (expirationTime < currentTime) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    // isRefreshTokenExpired = (expirationTime) => {
    //     const currentTime = Date.now();
    //     if (expirationTime < currentTime) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
}

export default AuthService;