import {
    configureStore,
    combineReducers,
} from "@reduxjs/toolkit";
import userSliceReducer from '../Redux/userSlice'
import instituteSliceReducer from "../Redux/instituteSlice"


const combinedReducer = combineReducers({
    user: userSliceReducer,
    institute:instituteSliceReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'user/logout') {
        state = undefined;
    }
    return combinedReducer(state, action);
};

export default configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(),
});