import { ThirdwebStorage } from "@thirdweb-dev/storage";

const FileUpload = async (file) => {
  const storage = new ThirdwebStorage({
    secretKey: process.env.REACT_APP_THIRD_WEB_SECRET_KEY,
    clientId: process.env.REACT_APP_THIRD_WEB_CLIENT_ID, // You can get one from dashboard settings
  });

  const UploadtoIPFS = async (file) => {
    try {
      const uri = await storage.upload(file);
      // This will log a URL like ipfs://QmWgbcjKWCXhaLzMz4gNBxQpAHktQK6MkLvBkKXbsoWEEy/0
      console.info(uri);
      return uri;
    } catch (error) {
      console.log("Error uploading file to IPFS.", error);
    }
  };

  const imageUrl = await UploadtoIPFS(file);
  return imageUrl;
};

export default FileUpload;
