import { getAccessToken, isAccessTokenExpired, isTokenExpired } from "./useAuth";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import AuthService from "../Services/AuthServices";
import Layout from "../components/layouts/Layout"
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setInstituteInfo } from "../Redux/instituteSlice"
import ApiServices from "../Services/ApiServices";
import { getSpecificInstitute } from "../components/Apis/Endpoint"

const PrivateRoutes = ({ redirectPath = "/login", children }) => {
    const accessToken = localStorage.getItem("accessToken");
    const value = isAccessTokenExpired(accessToken)
    const auth = AuthService.get()
    const navigate = useNavigate();


    if (value) {
        auth.Logout()  
        return <Navigate to={redirectPath} replace />;
      
    } else
        if (accessToken) {
            return <Layout>{children || <Outlet />} </Layout>;
        } else {
            return <Navigate to={redirectPath} replace />;
        }

}
export default PrivateRoutes;
