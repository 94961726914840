import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Content from "../sections/UserPublicPage/Content"
import "react-toastify/dist/ReactToastify.css";
import Header from "../layouts/Header";
import PublicPageHeader from "../layouts/PublicPageHeader";

class UserPublicPage extends Component {
  
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>Certificate | UserPublicPage</title>
          <meta name="description" content="#" />
        </MetaTags>
        <PublicPageHeader/>
        <Content />
      </Fragment>
    );
  }
}

export default UserPublicPage;
