import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { toast } from "react-toastify";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { generatePDF } from "../../../utility/PdfGenerate";
import UploaMetaData from "../../../utility/ipfsMetadata";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { getCertificateById } from "../../Apis/Endpoint";
import { CopyOutlined } from "@ant-design/icons";
import HandleStatusCertificate from "./HandleStatusCertificate";
import LinkExpire from "./LinkExpire";
import { Link } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { error } from "jquery";

const Content = () => {
  //wallet info using wagmi - start

  const chainType = process.env.REACT_APP_CHAIN;

  const { isConnected, address } = useAccount();

  const { chain, chains } = useNetwork();

  const { switchNetworkAsync, switchNetwork } = useSwitchNetwork();

  //wallet info using wagmi - end

  const [searchParams, setSearchParams] = useSearchParams();

  const [certificateImage, setImage] = useState(null);

  const [contractAddress, setContractAddress] = useState("");

  const [name, setName] = useState("");

  const { certificateId } = useParams();

  const [status, setStatus] = useState("pending");

  const [isLoading, setIsLoading] = useState(false);

  const [loader, setLoader] = useState(false);

  const [tokenId, setTokenId] = useState(null);

  const [isUrlExpire, setExpire] = useState(false);

  const [metamaskEthereum, setMetamaskEthereum] = useState(null);

  const [isMetamaskInstall, setIsMetamaskInstall] = useState(false);

  const chainId = chainType == "MAINNET" ? 137 : 80002;
  const chainHex = chainType == "MAINNET" ? "0x89" : "0x13881";

  console.log("chainId", chainId);
  console.log("chains", chains);

  useEffect(() => {
    //checking metamask install or not
    if (typeof window.ethereum !== "undefined") {
      setIsMetamaskInstall(true);
      setMetamaskEthereum(window.ethereum);
    } else {
      setIsMetamaskInstall(false);
    }

    //contract address from url
    const address = searchParams.get("address");
    setContractAddress(address);

    //api call to get created certificate info
    handleApiCall();
  }, []);

  const handleUserError = () => {
    if (chain.id != chainId) {
      handleChainSwitching();
    } else if (!isMetamaskInstall) {
      toast.error("Please install metamask extension");
    } else {
      handleClaim();
    }
  };

  function handleChainSwitching() {
    try {
      console.log(chain);
      switchNetwork?.(chainId);

      console.log(address);
    } catch (err) {
      console.log("metamask errro", err, err.code);
    }
  }

  async function handleApiCall() {
    const path = `${process.env.REACT_APP_BACKEND_BASE_URL}${getCertificateById}${certificateId}`;

    try {
      const res = await axios.get(path);

      const name = res.data?.certificate?.name;

      const from = res.data?.certificate?.from;

      const to = res.data?.certificate?.to;

      const expire = res.data?.certificate?.expire;

      if (
        res.data?.certificate?.tokenId !== undefined &&
        res.data?.certificate?.tokenId !== null
      ) {
        setTokenId(res.data?.certificate?.tokenId.toString());
        // setTokenId("123");
      }
      const expirationDate = new Date(expire);

      expirationDate.setUTCHours(0, 0, 0, 0);

      const currentDate = new Date();

      if (currentDate <= expirationDate) {
        setExpire(false);

        setStatus(res.data?.certificate?.status);

        setLoader(true);

        const str2 = name.charAt(0).toUpperCase() + name.slice(1);

        generateCertificateImage(str2, from, to);
      } else {
        setExpire(true);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  }

  const handleContractAddressCopy = () => {
    navigator.clipboard
      .writeText(contractAddress)
      .then(() => {
        toast.success("Address copy to Clipbord");
      })
      .catch((error) => {
        console.error("Failed to copy to clipboard:", error);
      });
  };

  const handleContractTokenId = () => {
    navigator.clipboard
      .writeText(tokenId)
      .then(() => {
        toast.success("Token id copy to Clipbord");
      })
      .catch((error) => {
        console.error("Failed to copy to clipboard:", error);
      });
  };

  const generateCertificateImage = async (name, from, to) => {
    try {
      // Generate the certificate image using the generatePDF function
      const image = await generatePDF(name, from, to);
      // Display the generated image
      console.log(image);
      setImage(image);

      // You can also perform additional actions or error handling here
      setLoader(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong or invalid url");
    }
  };

  const handleDownload = async () => {
    try {
      console.log(certificateImage);
      console.log("You call me ");
      const downloadLink = document.createElement("a");
      downloadLink.href = certificateImage; // Set the data URL as the link's href
      downloadLink.download = "certificate.png"; // Set the desired file name for download

      downloadLink.style.display = "none"; // Hide the link
      downloadLink.click(); // Programmatically click the link
      document.body.removeChild(downloadLink);
    } catch (err) {
      // toast.error("Something went wrong...")
    }
  };

  const addIntoMetamask = async (contractAddress, tokenId) => {
    try {
      const wasAdded = await metamaskEthereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC721",
          options: {
            address: contractAddress, // The address of the token.
            tokenId: tokenId, // ERC-721 or ERC-1155 token ID.
          },
        },
      });
      if (wasAdded) {
        toast.success("Certificate imported to your wallet");
      } else {
        // toast.error("Something went wrong...")
      }
    } catch (err) {
      console.log(err);
      toast.info(
        "Please import the certificate manually, and you'll find the NFT address and token ID below it or try again"
      );
    }
  };

  const handleClaim = async () => {
    try {
      // handleCheckChain();
      setIsLoading(true);
      const response = await fetch(certificateImage);
      const blob = await response.blob();
      const fileName = `${certificateId}_certificate.png`;
      const file = new File([blob], fileName, { type: "image/png" });
      const metaFileName = `${certificateId}_certificate`;
      const result = await UploaMetaData(file, metaFileName);
      console.log(result);

      const path = `${process.env.REACT_APP_BACKEND_BASE_URL}/nft/${chainId}/mint`;
      var config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      console.log(address);
      const res = await axios.post(
        path,
        {
          to: address,
          contractAddress: contractAddress,
          metadataUrl: result,
          certificateId: certificateId,
        },
        config
      );

      // const tokenIddb = res?.data?.tokenId.toString();
      // setTokenId(tokenIddb)
      // console.log(address)
      // setStatus('claimed')
      // setIsLoading(false)
      // toast.success(`Certificate claimed to this address ${address}`)
      // const tokenIddb = res?.data?.tokenId.toString();
      // setTokenId(tokenIddb)
      console.log(address);
      // setStatus('claimed')
      setStatus("processing");
      setIsLoading(false);
      toast.success(
        `Certificate claiming processing to this address ${address} has started. You will receive email when completed.`
      );
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="section">
      <div className="container">
        {isUrlExpire ? (
          <LinkExpire />
        ) : (
          <div class="d-flex align-items-center flex-column">
            <div className="d-flex justify-content-between button1">
              <h4 className="d-flex">Here’s your certificate!🎉</h4>
              <div className="d-flex">
                {isConnected ? (
                  <HandleStatusCertificate
                    status={status}
                    handleUserError={handleUserError}
                    tokenId={tokenId}
                    contractAddress={contractAddress}
                    handleDownload={handleDownload}
                    isLoading={isLoading}
                    addIntoMetamask={addIntoMetamask}
                  />
                ) : (
                  <>
                    <ConnectButton /> &nbsp;&nbsp;
                    <Link
                      className="downloadPDF-btn"
                      to={`https://docs.coindelta.io/application-guide/blockcertify`}
                      target="_blank"
                    >
                      How to claim <QuestionCircleOutlined />{" "}
                    </Link>
                  </>
                )}
              </div>
            </div>
            {loader ? (
              <Spin className="center" />
            ) : (
              <img src={certificateImage} width="790" height="790" />
            )}

            {status == "claimed" ? (
              <>
                <span>
                  NFT address : {contractAddress}{" "}
                  <CopyOutlined onClick={handleContractAddressCopy} />
                </span>
                <span>
                  Token Id : {tokenId}{" "}
                  <CopyOutlined onClick={handleContractTokenId} />
                </span>
              </>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};
export default Content;
