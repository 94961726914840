const chainType = process.env.REACT_APP_CHAIN;
const chainId = chainType == "MAINNET" ? 137 : 80002;
//auth
export const Login = "/auth/login";
export const signup = "/auth/signup";
export const verify = "/auth/verify";
export const refresh = "/auth/refresh";
export const resend = "/auth/resend";
export const currentUser = "/auth";
export const forgetPassword = "/auth/forget";
export const verifyOtpResetPassword = "/auth/changePassword";
export const Resend = "/auth/resend";

//deploy nft

export const deployNft = `/nft/${chainId}/deploy`;
export const mintNft = `/nft/${chainId}/mint`;

//institute
export const setUpInstitute = "/institute/setup";
export const getInstitute = "/institute/getIntitute/";
export const getSpecificInstitute = "/institute/getIntitute/";

//certificate
export const createCertificate = "/certificate/create";
export const getCertificateById = "/certificate/getAllCertificate/";
