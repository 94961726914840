import axios from "axios";
import { PDFDocument, rgb, degrees, StandardFonts, values } from 'pdf-lib';
import { toPng } from 'html-to-image';
import QRCode from 'qrcode';
import {toast} from "react-toastify"
// import certificate from "../../public/web4upskill_certificate_back.pdf"

const PDFJS = window.pdfjsLib;

const obj = {
    pagex: 700,
    pagey: 500,
    img: [
        {
            url: "https://i.ibb.co/mtBcskj/update-Certificate.png",
            x:0,
            y:0,
            width:700,
            height:500
        }
    ],
    text: [
        {   
            type:"name",
            size: 30,
            title: "saurav singh",
            operator: '',
            value: 0,
            y: 300,
            font: "TimesRomanBoldItalic",
            r: 0,
            g: 0,
            b: 0
        },
        {
            type:'fromDate',
            size:13,
            title:"31-08-2023",
            operator:'plus',
            value:52,
            y:230,
            font:"TimesRomanBoldItalic",
            r:0,
            g:0,
            b:0
        },
        {
            type:'toDate',
            size:13,
            title:"08-05-2023",
            operator:'plus',
            value:185,
            y:230,
            font:"TimesRomanBoldItalic",
            r:0,
            g:0,
            b:0
        },
    ]
}

async function renderPageAsImage(page) {

    // Create a blob from the page data
    const blob = new Blob([page], { type: 'application/pdf' });

    // Convert the blob to a data URL
    const pdfDataUrl = URL.createObjectURL(blob);

    // Create an HTML element to display the PDF
    const pdfElement = document.createElement('object');
    pdfElement.data = pdfDataUrl;
    pdfElement.type = 'application/pdf';

    // Append the PDF element to the document body
    document.body.appendChild(pdfElement);

    // Use html-to-image to convert the div to an image
    const image = await toPng(pdfElement);

    return image;
}


const fetchImageAndConvertToBytes = async (imageUrl) => {
    try {
        const response = await axios.get(imageUrl, { responseType: 'arraybuffer' });

        if (response.status === 200) {
            const imageBytes = new Uint8Array(response.data);
            return imageBytes;
        } else {
            console.error('Failed to fetch image from URL.');
            return null;
        }
    } catch (error) {
        console.error('Error fetching image from URL:', error);
        return null;
    }
};

async function setImage(value, pdfDoc, page) {
    try {

    } catch (err) {
        console.log(err);
    }
}




const generatePDF = async (name,from,to) => {
    const path = `${process.env.REACT_APP_BACKEND_BASE_URL}/getCertificatePdf/certificate`
    const data = await fetch(path); // Fetch the PDF file
    console.log(data)
    const existingPdfBuffer = await data.arrayBuffer(); // Convert to ArrayBuffer
    const existingPdfBytes = new Uint8Array(existingPdfBuffer); 
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const firstPage = pdfDoc.getPages()
    const page = firstPage[0]
    
    if(name){
        obj.text.map((value,index)=>{
            if(value.type=="name"){
                obj.text[index].title=name
            }

            if(value.type=="fromDate"){
                obj.text[index].title=from
            }

            if(value.type=="toDate"){
                obj.text[index].title=to
            }
        })
    }


    // for (const value of obj.img) {
    //     const imageByte = await fetchImageAndConvertToBytes(value.url);
    
    //     if (imageByte) {
    //       const newImage = await pdfDoc.embedPng(imageByte);
    
    //       const imageX = value.x || 100;
    //       const imageY = value.y || 10;
    //       const imageWidth = value.width || 700;
    //       const imageHeight = value.height || 500;
    
    //       page.drawImage(newImage, {
    //         x: imageX,
    //         y: imageY,
    //         width: imageWidth,
    //         height: imageHeight,
    //       });
    //     }
    //   }

    obj.text.map(async (value) => {
        console.log(value)
        const fontSize = parseInt(value.size)
        const font = await pdfDoc.embedFont(getFontType(value.font));
        console.log(font)
        const textWidth = await font.widthOfTextAtSize(value.title, fontSize);
        let centerX = ((page.getWidth() - textWidth) / 2);
        if (value.operator == 'plus') {
            centerX = centerX + parseInt(value.value)
        } else if (value.operator == 'mine') {
            centerX = centerX - parseInt(value.value)
            alert(centerX)
        } else {
            centerX = ((page.getWidth() - textWidth) / 2)
        }
        page.drawText(value.title, {
            x: centerX,
            y: value.y,
            size: fontSize,
            font: font,
            color: rgb(value.r, value.g, value.b),
        })

    })

    const qrCodeDataUrl = await QRCode.toDataURL('https://example.com', {
        width: 200,
        height: 200,
      });

      // Embed the QR code image in the PDF
      const qrCodeImage = await pdfDoc.embedPng(qrCodeDataUrl);

      // Draw the QR code image on the PDF page
    //   const qrCodeDims = qrCodeImage.scale(0.5);
    //   page.drawImage(qrCodeImage, {
    //     x: 150,
    //     y: 200,
    //     width: qrCodeDims.width,
    //     height: qrCodeDims.height,
    //   });



    const pdfBytes = await pdfDoc.save();


    const canvas = document.createElement("canvas");

    canvas.setAttribute("className", "canv");

    let canv = document.querySelector(".canv");

    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' })

    const uri = URL.createObjectURL(pdfBlob);
    // return uri

    var pdf = await PDFJS.getDocument({ url: uri });

    var newpage = await pdf.getPage(1);

    var viewport = newpage.getViewport({ scale: 10 });

    canvas.height = viewport.height;

    canvas.width = viewport.width;

    var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport
    };

    await newpage.render(render_context).promise;
    let img = canvas.toDataURL("image/png", 1.0);
    return img;
    // const downloadLink = document.createElement('a');
    // downloadLink.href = img; // Set the data URL as the link's href
    // downloadLink.download = 'certificate.png'; // Set the desired file name for download

    // // Append the download link to the document
    // document.body.appendChild(downloadLink);

    // // Trigger a click event on the download link to start the download
    // downloadLink.style.display = 'none'; // Hide the link
    // downloadLink.click(); // Programmatically click the link

    // // Clean up by removing the download link
    // document.body.removeChild(downloadLink);
}


function getFontType(fontName) {
    switch (fontName) {
        case fontName = "TimesRoman":
            return StandardFonts.TimesRoman
            break;
        case fontName = "TimesRomanBold":
            return StandardFonts.TimesRomanBold
            break;
        case fontName = "TimesRomanBoldItalic":
            return StandardFonts.TimesRomanBoldItalic
            break;
        case fontName = "TimesRomanBoldItalic":
            return StandardFonts.TimesRomanBoldItalic
            break;
        default:
            console.log("No font...")
    }
}


export { generatePDF };