import React, { Fragment, useEffect, useState } from "react";
import Menu from "../layouts/Menu";
// import HeaderComponent from "../../helper/Navigationhelper";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { toast } from "react-toastify";
// import AuthService from "../../Services/AuthService";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/misc/coindelta-1.svg";
import { ConnectButton } from '@rainbow-me/rainbowkit'

const PublicPageHeader = () => {
  const navigate = useNavigate();
  const [navtoggle, setnavtoggle] = useState(false);
  const [isTop, setisTop] = useState(false);
  function navtoggleClass() {
    setnavtoggle(!navtoggle);
  }
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        setisTop(window.scrollY > 100);
      },
      false
    );
  }, []);

  const logoutHandler = () => {
    // if (AuthService.get().logout()) {
    //   dispatch(logout());
    //   navigate("/login");
    // }
  };

  // const stickyheader = isTop ? "sticky" : "";


  return (
    <Fragment>
      <aside className={classNames("main-aside", { open: navtoggle })}>
        <div className="aside-title">
          <div className="aside-controls aside-trigger">
            <h4>Menu</h4>
            <div className="close-btn close-dark" onClick={navtoggleClass}>
              <span />
              <span />
            </div>
          </div>
        </div>
      </aside>
      <div className="aside-overlay aside-trigger" onClick={navtoggleClass} />
      {/* Header Start */}
      <header
        className={`main-header header-fw can-sticky header-1 sticky`}
      >
        <nav className="navbar">
        <Link className="navbar-brand" to='#'>
        {" "}
        <img src={Logo} alt="1" border="0" />
        </Link>
          <div className="header-controls">
            <ul className="header-controls d-sm-flex">
              <li>
                <span className="">
                </span>
              </li>
            </ul>
            {/* Toggler */}
            <div
              className="aside-toggler aside-trigger"
              onClick={navtoggleClass}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
        </nav>
       
      </header>
      {/* Header End */}
    </Fragment>
  );
};

export default PublicPageHeader;
