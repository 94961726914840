import { createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../Services/AuthServices";
import {getInstitute,currentUser}  from "../components/Apis/Endpoint"

import ApiServices from "../Services/ApiServices";

const GetCurrentUser = createAsyncThunk("user", async () => {
    const userIdFromStorage = localStorage.getItem("userId");
    if(userIdFromStorage){
        const response = await ApiServices.get(`${currentUser}/${userIdFromStorage}`)
        return response.data.data
    }
})

const GetCurrentInstitute = createAsyncThunk('institute',async ()=>{
    const userIdFromStorage = localStorage.getItem("userId");
    console.log(userIdFromStorage)
    if(userIdFromStorage){
        const response = await ApiServices.get(`${getInstitute}${userIdFromStorage}`)
        console.log(response)
        return response.data.institute
    }
})

export{ GetCurrentUser , GetCurrentInstitute}