import React, { useEffect, useState } from "react";
import userCertificate from "../../../assets/img/misc/Frame_21.png";
import { Button, Spin, Typography } from "antd";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { Handler } from "leaflet";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { generatePDF } from "../../../utility/PdfGenerate";
import UploaMetaData from "../../../utility/ipfsMetadata";
import { Link } from "react-router-dom";
import AuthService from "../../../Services/AuthServices";
import ApiServices from "../../../Services/ApiServices";
import axios from "axios";
import { getCertificateById } from "../../Apis/Endpoint";
import { QuestionCircleOutlined } from "@ant-design/icons";
export default function PendingComponent({
  handleUserError,
  isLoading,
  status,
}) {
  return (
    <>
      {status === "pending" ? (
        <>
          <button
            type="button"
            className={"downloadPDF-btn"}
            onClick={handleUserError}
          >
            {" "}
            {isLoading ? (
              <>
                <span
                  disabled
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Wait...
              </>
            ) : (
              "Claim"
            )}
          </button>
          <Link
            className="downloadPDF-btn"
            to={`https://docs.coindelta.io/application-guide/blockcertify`}
            target="_blank"
          >
            How to claim <QuestionCircleOutlined />{" "}
          </Link>
        </>
      ) : null}
    </>
  );
}
