import React, { useEffect, useState } from "react";
import userCertificate from "../../../assets/img/misc/Frame_21.png";
import { Button, Spin, Typography } from "antd";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { Handler } from "leaflet";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { generatePDF } from "../../../utility/PdfGenerate";
import UploaMetaData from "../../../utility/ipfsMetadata";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import AuthService from "../../../Services/AuthServices";
import ApiServices from "../../../Services/ApiServices";
import axios from "axios";
import { getCertificateById } from "../../Apis/Endpoint";
import { CopyOutlined } from "@ant-design/icons";

import ClaimedComponent from "./ClaimedComponent";
import PendingComponent from "./PendingComponent";

export default function HandleStatusCertificate({
  status,
  handleUserError,
  tokenId,
  contractAddress,
  handleDownload,
  isLoading,
  addIntoMetamask,
}) {
  return (
    <>
      {status == "claimed" ? (
        <ClaimedComponent
          handleDownload={handleDownload}
          tokenId={tokenId}
          contractAddress={contractAddress}
          addIntoMetamask={addIntoMetamask}
        />
      ) : (
        <PendingComponent
          handleUserError={handleUserError}
          isLoading={isLoading}
          status={status}
        />
      )}
    </>
    // <><button
    // type="button"

    // className={isConnected ? 'downloadPDF-btn' : 'disable'} onClick={handleDownload}>Download</button> &nbsp;&nbsp;&nbsp;
    // <button
    //     type="button"
    //     className={isConnected ? 'downloadPDF-btn' : 'disable'}
    //     onClick={handleClaim}
    // > {isLoading ? (
    //     <>
    //         <span
    //             disabled
    //             className="spinner-grow spinner-grow-sm"
    //             role="status"
    //             aria-hidden="true"
    //         ></span>
    //         Wait...
    //     </>
    // ) : (
    //     "claim"
    // )}</button> </>
  );
}
