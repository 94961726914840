import React, { useEffect, useState } from "react";
import userCertificate from "../../../assets/img/misc/Frame_21.png";
import { Button, Spin, Typography } from "antd";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { Handler } from "leaflet";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { generatePDF } from "../../../utility/PdfGenerate";
import UploaMetaData from "../../../utility/ipfsMetadata";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AuthService from "../../../Services/AuthServices";
import ApiServices from "../../../Services/ApiServices";
import axios from "axios";
import { getCertificateById } from "../../Apis/Endpoint";
import { ArrowUpOutlined } from "@ant-design/icons";
import metaMaskIcon from "../../../assets/img/misc/metamask.svg";

export default function ClaimedComponent({
  handleDownload,
  tokenId,
  contractAddress,
  addIntoMetamask,
}) {
  return (
    <>
      <button
        type="button"
        className="downloadPDF-btn"
        onClick={() => {
          addIntoMetamask(contractAddress, tokenId);
        }}
      >
        Import into <img src={metaMaskIcon} width={25} />
      </button>{" "}
      &nbsp;&nbsp;&nbsp;
      <button
        type="button"
        className="downloadPDF-btn"
        onClick={handleDownload}
      >
        Download
      </button>{" "}
      &nbsp;&nbsp;&nbsp;
      <Link
        className="downloadPDF-btn"
        to={`https://testnets.opensea.io/assets/amoy/${contractAddress}/${tokenId}`}
        target="_blank"
      >
        View NFT <ArrowUpOutlined />
      </Link>
    </>
  );
}
