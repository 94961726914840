import React from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Layout from '../components/layouts/Layout';

const PublicRoutes = ({ redirectPath = "/login", children }) => {
    const accessToken = localStorage.getItem("accessToken");
    console.log("Access Token:", accessToken); // Check if the access token is retrieved

    if (!accessToken) {
        console.log("Redirecting to:", redirectPath); // Check if redirection is happening
        return <>{children || <Outlet/>} </>;
    } else {
        return <Navigate to='/'  />;
    }
}


export default PublicRoutes;
