import { createSlice } from "@reduxjs/toolkit";
import {GetCurrentInstitute}  from "./ApiServices"

const initialState = {
    instituteInfo: {},
    isLoading: false,
    error: null,
};

export  const instituteSlice = createSlice({
    name:'institute',
    initialState,
    reducers:{
        setInstituteInfo:(state,action)=>{
            state.instituteInfo=action.payload;
        }
    },
    extraReducers :(builder)=>{
        builder.addCase(GetCurrentInstitute.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(GetCurrentInstitute.fulfilled, (state, action) => {
            state.instituteInfo = action.payload;
            state.isLoading = false;
        })
        .addCase(GetCurrentInstitute.rejected, (state, action) => {
            state.error = action.error.message;
            state.isLoading = false;
        });
    }

});
export const {setInstituteInfo} = instituteSlice.actions;

export default instituteSlice.reducer;
