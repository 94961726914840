import React, { Fragment, useEffect, useState } from "react";
import Menu from "../layouts/Menu";
// import HeaderComponent from "../../helper/Navigationhelper";
import { Link } from "react-router-dom";
import classNames from "classnames";
// import AuthService from "../../Services/AuthService";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../../Services/AuthServices";
import { logout } from "../../Redux/userSlice";
import { Button } from "antd";
import { toast } from "react-toastify";

const Header = () => {
  const instituteId = localStorage.getItem("instituteId")
  const Navigate = useNavigate()
  const dispatch = useDispatch()
  const [navtoggle, setnavtoggle] = useState(false);
  const [isTop, setisTop] = useState(false);
  function navtoggleClass() {
    setnavtoggle(!navtoggle);
  }
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        setisTop(window.scrollY > 100);
      },
      false
    );
  }, []);

  const HandleLogout = () => {
    const authService = AuthService.get()
    authService.Logout()
    dispatch(logout())
    Navigate('/login')
    toast.success("Sucessfully logged out")
  }

  // const stickyheader = isTop ? "sticky" : "";


  return (
    <Fragment>
      <aside className={classNames("main-aside", { open: navtoggle })}>
        <div className="aside-title">
          <div className="aside-controls aside-trigger">
            <h4>Menu</h4>
            <div className="close-btn close-dark" onClick={navtoggleClass}>
              <span />
              <span />
            </div>
          </div>
        </div>
      </aside>
      <div className="aside-overlay aside-trigger" onClick={navtoggleClass} />
      {/* Header Start */}
      <header
        className={`main-header header-fw can-sticky header-1 sticky`}
      >
        <nav className="navbar">
          <Menu />
          <div className="header-controls">
            <ul className="header-controls d-sm-flex">
              <li>
                <span className="">
                </span>
              </li>
            </ul>
            {/* Toggler */}
            <div
              className="aside-toggler aside-trigger"
              onClick={navtoggleClass}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
        </nav>
        <nav className="navbar" style={{ marginTop: "2rem" }}>
          <Button
            className="logout"
            onClick={HandleLogout}
          >Logout</Button>
        </nav>
      </header>

      {/* Header End */}
    </Fragment>
  );
};

export default Header;
