import React, { useEffect, useState } from "react";
// import localImgLoad from "../../lib/localImgLoad";

function ImageContainer({ imageUrl,style }) {
  const [usingGateway, setUsingGateway] = useState(
    process.env.REACT_APP_IPFS_GATEWAY
  );
  const [index, setIndex] = useState(0);
  //   const [loader, setLoader] = useState(false);
  //   const [imageType, setImageType] = useState("");
  const REACT_APP_BACKUP_IPFS_GATEWAYS =
    process.env.REACT_APP_BACKUP_IPFS_GATEWAY.split(",");

  useEffect(() => {
    if (imageUrl) {

      const extension = imageUrl[0].split(".");
      console.log("extension", extension[extension.length - 1],imageUrl);
      //   setImageType(extension[extension.length - 1]);
    }
  }, [imageUrl]);

  useEffect(() => {
    if (index) {
      setUsingGateway(REACT_APP_BACKUP_IPFS_GATEWAYS[index]);
      //   setLoader(false);
    }
  }, [index]);

  return (
    <>
        <img
          onError={(error) => {
            if (error) {
              if (index < REACT_APP_BACKUP_IPFS_GATEWAYS.length - 1) {
                setIndex((prev) => prev + 1);
                // setLoader(true);
              }
            }
          }}
          src={
            imageUrl
              ? imageUrl.includes("ipfs://")
                ? usingGateway + imageUrl.split("ipfs://")[1]
                : imageUrl
              : ""
          }
          alt="banner"
          style={style}
        />
    </>
  );
}

export default ImageContainer;
