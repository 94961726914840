import { ThirdwebStorage } from "@thirdweb-dev/storage";

const UploaMetaData = async (file, name) => {
  const storage = new ThirdwebStorage({
    secretKey: process.env.REACT_APP_THIRD_WEB_SECRET_KEY,
    clientId: process.env.REACT_APP_THIRD_WEB_CLIENT_ID, // You can get one from dashboard settings
  });
  console.log("file:::", file, name);

  const UploadtoIPFS = async (file) => {
    try {
      const uri = await storage.upload(file);
      // This will log a URL like ipfs://QmWgbcjKWCXhaLzMz4gNBxQpAHktQK6MkLvBkKXbsoWEEy/0
      console.info(uri);
      return uri;
    } catch (error) {
      console.log("Error uploading file to IPFS.", error);
    }
  };

  async function UploadToIPFSMetadata(imageIPFSURL, name) {
    try {
      const parts = name.split(" ");
      const firstName = parts[0];
      const metadata = {
        description: "",
        external_url: "",
        image: "",
        name: "",
        attributes: [],
      };

      metadata.name = firstName;
      metadata.image = imageIPFSURL;
      metadata.description =
        "Congratulations on successfully getting your Certificate! This Certificate is verified by the company and doesn't required any signature.";

      const metadataJson = JSON.stringify(metadata);

      const uri = await storage.upload(metadataJson);
      // This will log a URL like ipfs://QmWgbcjKWCXhaLzMz4gNBxQpAHktQK6MkLvBkKXbsoWEEy/0
      console.info(uri);
      return uri;
    } catch (error) {
      console.log("Error uploading file to IPFS.", error);
    }
  }

  const imageUrl = await UploadtoIPFS(file);
  const metadataUrl = await UploadToIPFSMetadata(imageUrl, name);
  return metadataUrl;
};

export default UploaMetaData;
