import React, { Fragment } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/misc/coindelta-1.svg";
import { toast } from "react-toastify";
import { Button } from "antd";
import AuthService from "../../Services/AuthServices";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/userSlice";
export default function Menu() {
  const Navigate = useNavigate()
  const userInfo = useSelector((state) => state.user)
  const dispatch = useDispatch()

  return (
    <Fragment>
      <Link className="navbar-brand" to="/">
        {" "}
        <img src={Logo} alt="1" border="0" />
      </Link>
      {
        userInfo?.userInfo?.isNewUser
          ?
          null
          :
          <ul className="navbar-nav">
            <li className="menu-item menu-item-has-children">
              <NavLink exact activeClassName="active" to="/">Dashboard</NavLink>
            </li>
            <li className="menu-item menu-item-has-children">
              <NavLink activeClassName="active" to="/profile">Institute Profile</NavLink>
            </li>
            {/* <li className="menu-item menu-item-has-children">
          <Link>Subscription</Link>
        </li> */}
          </ul>
      }
    </Fragment>
  );
}
